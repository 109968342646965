<template>
    <div class="grid">
        <div class="grid col-12 md:col-12">
            <div class="col-12 xl:col-8">
                <div class="card visualizacao">
                    <div class="flex justify-content-center">
                        <h5>FICHA TÉCNICA DE PRODUTO – PORTFÓLIO SEBRAE-PE</h5>
                    </div>
                    <div>
                        <div class="mb-4">
                            <div class="card">
                                <h5 class="flex justify-content-center mb-5">Informações Gerais do Produto/Serviço</h5>
                                <detalhe v-if="mostrarCampo(solucao?.id)" titulo="Código:" :size="sizeForm" :negrito="negritoForm">{{ solucao.id }}</detalhe>
                                <detalhe v-if="mostrarCampo(solucao?.title)" titulo="Título do Produto:" :size="sizeForm" :negrito="negritoForm">{{
                                    solucao.title
                                }}</detalhe>
                                <detalhe v-if="mostrarCampo(solucao?.justificativa)" titulo="Objetivo/Justificativa:" :size="sizeForm" :negrito="negritoForm"
                                    ><span v-html="solucao.justificativa"
                                /></detalhe>
                                <detalhe v-if="mostrarCampo(solucao?.resultadoEsperado)" titulo="Resultado Esperado:" :size="sizeForm" :negrito="negritoForm"
                                    ><span v-html="solucao.resultadoEsperado"
                                /></detalhe>
                                <detalhe
                                    v-if="mostrarCampo(solucao?.descricaoAtividadesMateriais)"
                                    titulo="Descrição das Atividades / Materiais:"
                                    :size="sizeForm"
                                    :negrito="negritoForm"
                                    ><span v-html="solucao.descricaoAtividadesMateriais"
                                /></detalhe>
                                <detalhe v-if="mostrarCampo(solucao?.produtoEntrega)" titulo="Produto de Entrega	:" :size="sizeForm" :negrito="negritoForm"
                                    ><span v-html="solucao.produtoEntrega"
                                /></detalhe>
                                <detalhe v-if="solucao?.atividades?.length" titulo="Atividades	:" :size="sizeForm" :negrito="negritoForm">
                                    <ul>
                                        <li v-for="atividade in solucao.atividades" :key="atividade">
                                            {{ atividade.descricao }} {{ atividade?.quantidadeHora ? atividade?.quantidadeHora + 'h.' : '' }}
                                        </li>
                                    </ul>
                                </detalhe>
                                <detalhe v-if="mostrarCampo(solucao?.cargaHoraria)" titulo="Carga Horária:" :size="sizeForm" :negrito="negritoForm">{{
                                    decimalToHours(solucao.cargaHoraria)
                                }}</detalhe>
                                <detalhe
                                    v-if="solucao?.descricaoProduto?.length"
                                    titulo="Produtos de Entrega por Etapas:"
                                    :size="sizeForm"
                                    :negrito="negritoForm"
                                >
                                    <ul>
                                        <li v-for="descricao in solucao.descricaoProduto" :key="descricao">
                                            {{ descricao.descricao }}
                                        </li>
                                    </ul>
                                </detalhe>
                                <detalhe v-if="mostrarCampo(solucao?.tipoServico)" titulo="Tipo de Serviços:" :size="sizeForm" :negrito="negritoForm">{{
                                    solucao.tipoServico.tituloComposto
                                }}</detalhe>
                                <detalhe
                                    v-if="mostrarCampo(solucao?.minimoParticipantes)"
                                    titulo="Nº Min. Participantes:"
                                    :size="sizeForm"
                                    :negrito="negritoForm"
                                    >{{ solucao.minimoParticipantes }}</detalhe
                                >
                                <detalhe
                                    v-if="mostrarCampo(solucao?.maximoParticipantes)"
                                    titulo="Nº Max. Participantes:"
                                    :size="sizeForm"
                                    :negrito="negritoForm"
                                    >{{ solucao.maximoParticipantes }}</detalhe
                                >
                                <detalhe v-if="mostrarCampo(solucao?.active)" titulo="Situação:" :size="sizeForm" :negrito="negritoForm">{{
                                    solucao.active == true ? 'Ativo' : 'Inativo'
                                }}</detalhe>
                            </div>
                            <div class="card">
                                <h5 class="flex justify-content-center mb-5">Orientações para Oferta</h5>
                                <detalhe v-if="solucao?.publicoAlvo?.length" titulo="Público Alvo:" :size="sizeForm" :negrito="negritoForm">
                                    <ul>
                                        <li v-for="publico in solucao.publicoAlvo" :key="publico">
                                            {{ publico.title }}
                                        </li>
                                    </ul>
                                </detalhe>
                                <detalhe v-if="solucao?.setoresEconomicos?.length" titulo="Setores Economicos:" :size="sizeForm" :negrito="negritoForm">
                                    <ul>
                                        <li v-for="setorEconomico in solucao.setoresEconomicos" :key="setorEconomico">
                                            {{ setorEconomico.title }}
                                        </li>
                                    </ul>
                                </detalhe>
                                <detalhe
                                    v-if="solucao?.macrossegmentosEconomicos?.length"
                                    titulo="Macrossegmentos Econômicos:"
                                    :size="sizeForm"
                                    :negrito="negritoForm"
                                >
                                    <ul>
                                        <li v-for="macrossegmentoEconomico in solucao.macrossegmentosEconomicos" :key="macrossegmentoEconomico">
                                            {{ macrossegmentoEconomico.title }}
                                        </li>
                                    </ul>
                                </detalhe>
                                <detalhe v-if="solucao?.personas?.length" titulo="Personas:" :size="sizeForm" :negrito="negritoForm">
                                    <ul>
                                        <li v-for="persona in solucao.personas" :key="persona">
                                            {{ persona.title }}
                                        </li>
                                    </ul>
                                </detalhe>
                                <detalhe v-if="mostrarCampo(solucao?.observacoesOrientacoes)" titulo="Observações:" :size="sizeForm" :negrito="negritoForm"
                                    ><span v-html="solucao.observacoesOrientacoes"
                                /></detalhe>
                            </div>
                            <div class="card">
                                <h5 class="flex justify-content-center mb-5">Fornecedor / Contratação</h5>
                                <detalhe v-if="mostrarCampo(solucao?.textoVenda)" titulo="Texto de venda	:" :size="sizeForm" :negrito="negritoForm"
                                    ><span v-html="solucao.textoVenda"
                                /></detalhe>
                                <detalhe v-if="mostrarCampo(solucao?.preco)" titulo="Preço p/ Cliente:" :size="sizeForm" :negrito="negritoForm">{{
                                    formatarValor(solucao.preco)
                                }}</detalhe>
                                <detalhe
                                    v-if="mostrarCampo(solucao?.origemContratacao)"
                                    titulo="Origem de Contratação:"
                                    :size="sizeForm"
                                    :negrito="negritoForm"
                                    >{{ solucao.origemContratacao.title }}</detalhe
                                >

                                <detalhe
                                    v-if="mostrarCampo(solucao?.perfilExigido)"
                                    titulo="Perfil Exigido para o credenciado:"
                                    :size="sizeForm"
                                    :negrito="negritoForm"
                                >
                                    <span v-html="solucao.perfilExigido"></span>
                                </detalhe>
                                <detalhe
                                    v-if="mostrarCampo(solucao?.contratacaoCredenciados)"
                                    titulo="Contratação de Credenciados:"
                                    :size="sizeForm"
                                    :negrito="negritoForm"
                                    >{{ solucao.contratacaoCredenciados.title }}</detalhe
                                >
                                <detalhe v-if="solucao?.subAreas?.length" titulo="Área/Sub-Área:" :size="sizeForm" :negrito="negritoForm">
                                    <ul>
                                        <li>
                                            {{ obterAreaSubAreaPrioritaria().subArea?.area?.title + ' / ' + obterAreaSubAreaPrioritaria().subArea?.title }}
                                        </li>
                                    </ul>
                                </detalhe>
                            </div>
                            <div class="card">
                                <h5 class="flex justify-content-center mb-5">Integração SAS</h5>
                                <detalhe v-if="mostrarCampo(solucao?.codSolucaoSas)" titulo="Cod. Solução SAS:" :size="sizeForm" :negrito="negritoForm">{{
                                    solucao.codSolucaoSas
                                }}</detalhe>
                            </div>
                        </div>
                    </div>
                </div>
                <Button @click="voltar" label="Voltar" icon="pi pi-arrow-left" class="mr-2 p-button-secondary"></Button>
                <Button @click="editar" label="Editar" icon="pi pi-pencil" v-if="$temAcessoView('SOL-001')"></Button>
                <Button @click="pdf" class="ml-2" label="PDF Download" icon="pi pi-file-pdf" v-if="$temAcessoView('SOL-000')"></Button>
            </div>
            <div class="col-12 xl:col-4">
                <div class="card">
                    <div class="flex justify-content-center">
                        <h5>Solução Arquivos</h5>
                    </div>
                    <arquivos :classe="classe" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import jsPDF from 'jspdf';
import Arquivos from '../components/Arquivos.vue';
import Services from './service';

export default {
    components: { Arquivos },
    data() {
        return {
            modal: false,
            sizeForm: '190',
            negritoForm: false,
            classe: {
                id: this.$route.params.id,
                nome: 'Solucao',
            },
            solucao: {
                title: null,
                nomeComercial: null,
                tipoServico: {
                    id: null,
                },
                politicaPreco: {
                    id: null,
                },
                origemContratacao: {
                    id: null,
                },
                contratacaoCredenciados: {
                    id: null,
                },
                tipoExecucao: null,
                complexidade: {
                    id: null,
                },
                metodologia: {
                    id: null,
                },
                complexidadeMetodologia: {
                    id: null,
                },
                publicoAlvo: [],
                preco: null,
                temas: [],
                modalidades: [],
                setoresEconomicos: [],
                macrossegmentosEconomicos: [],
                persona: [],
                unidadeOrganizacional: null,
                dores: [],
                atividades: [],
                descricaoProduto: [],
                justificativa: null,
                resultadoEsperado: null,
                descricaoAtividadesMateriais: null,
                produtoEntrega: null,
                atualizarValorHora: null,
                atividadeVenda: null,
                minimoParticipantes: null,
                maximoParticipantes: null,
                textoVenda: null,
                observacoesOrientacoes: null,
                necessitaAprovacao: null,
                perfilExigido: null,
                valorVemEmpresa: null,
                subAreas: [],
                palavrasChave: null,
                codSolucaoSas: null,
                emiteCertificado: null,
                urlSolucao: null,
                pdf: false,
            },
        };
    },

    mounted() {
        this.obterSolucao();
    },

    methods: {
        pdf() {
            const pageWidth = 550;
            const scaleFactor = 1;

            let div = document.createElement('div');
            div.style.width = `${pageWidth / scaleFactor}px`;
            div.style.textAlign = 'justify';
            div.innerHTML = document.querySelector('.visualizacao').outerHTML;
            document.body.appendChild(div);

            const fontSize = 12 * scaleFactor;
            div.style.fontSize = `${fontSize}px`;

            div.style.transform = `scale(${scaleFactor})`;

            const pdf = new jsPDF('p', 'pt', 'a4', true);

            pdf.html(div, {
                margin: [60, 30, 40, 30],
                autoPaging: 'text',
                callback: (pdf) => {
                    const regex = /[^a-zA-Z0-9-_]/g;
                    pdf.save(this.solucao.title?.replace(regex, '') + '.pdf');
                },
            });

            document.body.removeChild(div);
        },

        voltar() {
            if (this.$route.query.view == 'vitrine') {
                this.$router.push({
                    name: 'Vitrine',
                });
            } else {
                this.$router.push({
                    name: 'Solucoes',
                });
            }
        },

        obterSolucao() {
            if (this.$route.params.id) {
                this.$store.dispatch('addRequest');
                Services.obterPorId(this.$route.params.id).then((response) => {
                    if (response?.success) {
                        this.solucao = response.data;
                        this.solucao.descricaoProduto = this.removerNaoSeAplica(this.solucao.descricaoProduto);
                        this.solucao.publicoAlvo = this.removerNaoSeAplica(this.solucao.publicoAlvo);
                        this.solucao.macrossegmentosEconomicos = this.removerNaoSeAplica(this.solucao.macrossegmentosEconomicos);
                        this.solucao.setoresEconomicos = this.removerNaoSeAplica(this.solucao.setoresEconomicos);
                        this.solucao.personas = this.removerNaoSeAplica(this.solucao.personas);
                        this.solucao.subAreas = this.removerNaoSeAplica(this.solucao.subAreas);
                    }
                    this.$store.dispatch('removeRequest');
                });
            }
        },

        editar() {
            this.$router.push({
                name: `Solucao_Atualizar`,
                params: {
                    id: this.solucao.id,
                },
            });
        },

        formatarValor(valor) {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(valor);
        },

        decimalToHours(decimal) {
            const horas = Math.floor(decimal);
            const minutos = Math.round((decimal - horas) * 60);
            return `${horas}:${minutos.toString().padStart(2, '0')}`;
        },
        mostrarCampo(campo) {
            return campo != null && campo !== '' && campo.toString().toUpperCase() !== 'NÃO SE APLICA';
        },
        removerNaoSeAplica(lista) {
            if (lista) {
                return lista.filter((item) => item?.title?.toUpperCase() !== 'NÃO SE APLICA');
            }
        },
        obterAreaSubAreaPrioritaria() {
            if (this.solucao?.subAreas) {
                let subArea = this.solucao.subAreas.find((subArea) => subArea.prioridade === 1);
                if (!subArea) {
                    subArea = this.solucao.subAreas.find((subArea) => subArea.prioridade === 0);
                }
                return subArea;
            }
        },
    },
};
</script>

<style></style>
