import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SOL}${process.env.VUE_APP_API_PATH}/sol`;

const urlBase = `${api}/arquivo`;


export default {
    uploadArquivo(template,  onUploadProgress) {
        const formDataType = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                onUploadProgress(loaded, total)
            }
        
        };

        const formData = new FormData();
        formData.append('id', template.id)
        formData.append('classe', template.classe)
        formData.append('title', template.title)
        formData.append('disponivelCliente', template.disponivelCliente)
        formData.append('imagem', template.imagem)
        formData.append('file', template.arquivo)
        return axiosJwt.post(`${urlBase}/upload/${template.classe}/${template.id}/${template.title}`, formData, formDataType);
    },
    
    obterArquivos(id, classe, active = true) {
        return axiosJwt.get(`${urlBase}/${classe}/${id}?active=${active}`)
    },

    atualizarArquivo(uuid, arquivo) {
        return axiosJwt.put(`${urlBase}/${uuid}`, arquivo);
    },

    excluirArquivo(uuid) {
        return axiosJwt.delete(`${urlBase}/${uuid}`);
    },

    downloadFileUrl(url) {
        return axiosJwt.get(url, {
            responseType: 'blob',
        });
    },
};
