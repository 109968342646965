<template>
    <div class="field col-12">
        <FileUpload
            :customUpload="true"
            @uploader="arquivoSelecionado"
            :multiple="true"
            chooseLabel="Selecionar Arquivo"
            uploadLabel="Upload Arquivo"
            cancelLabel="Limpar"
            ref="fileUpload"
            v-if="$temAcessoView('SOL-001')"
        />
        <modal-arquivo
            v-if="modal"
            :modal="modal"
            :objeto="templates"
            :isAtualizar="isAtualizar"
            @closeModal="modalClose()"
            @salvar="uploadArquivo"
            @atualizar="atualizar"
        />
        <DataTable v-if="arquivosExibir.length > 0" :value="arquivosExibir" class="field justify-content-start" responsiveLayout="scroll">
            <Column>
                <template #body="slotProps">
                    <template v-if="!(slotProps.data && slotProps.data.progress)">
                        <Button
                            type="button"
                            title="Opções"
                            icon="pi pi-cog"
                            class="p-button-text p-button-lg"
                            @click="toggle($event, slotProps.data)"
                            aria-haspopup="true"
                            aria-controls="overlay_menu"
                        />
                        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
                    </template>
                    <template v-else>
                        <ProgressBar :value="slotProps.data.progress" />
                    </template>
                </template>
            </Column>
            <Column class="md:col-9" field="title" header="Arquivos"></Column>
        </DataTable>
    </div>
</template>

<script>
import ModalArquivo from './ModalArquivo.vue';
import ServiceArquivo from '../shared/serviceArquivos';

export default {
    components: { ModalArquivo },
    props: {
        classe: {
            type: Object,
        },
    },
    data() {
        return {
            objeto: null,
            modal: false,
            arquivos: [],
            templates: [],
            arquivosLoading: [],
            isAtualizar: false,
            items: [
                {
                    label: 'Opções',
                    items: [
                        {
                            label: 'Baixar',
                            icon: 'pi pi-download',
                            command: () => {
                                this.download(this.objeto);
                            },
                        },
                        {
                            label: 'Editar',
                            icon: 'pi pi-pencil',
                            command: () => {
                                if (this.$temAcessoView('SOL-001')) {
                                    this.atualizarArquivo(this.objeto);
                                } else {
                                    this.$toast.add({
                                        severity: 'error',
                                        summary: 'Não Autorizado',
                                        detail: 'Você não tem permissão para Editar',
                                        life: 3000,
                                    });
                                }
                            },
                        },
                        {
                            label: 'Excluir',
                            icon: 'pi pi-trash',
                            command: () => {
                                if (this.$temAcessoView('SOL-001')) {
                                    this.confirmarExclusao(this.objeto);
                                } else {
                                    this.$toast.add({
                                        severity: 'error',
                                        summary: 'Não Autorizado',
                                        detail: 'Você não tem permissão para Excluir',
                                        life: 3000,
                                    });
                                }
                            },
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        this.obterArquivos();
    },
    computed: {
        arquivosExibir() {
            return this.arquivosLoading.concat(this.arquivos);
        },
    },
    methods: {
        toggle(event, objeto) {
            this.objeto = objeto;
            this.$refs.menu.toggle(event);
        },
        obterArquivos() {
            ServiceArquivo.obterArquivos(this.classe.id, this.classe.nome).then((response) => {
                if (response.success) {
                    this.arquivos = response.data;
                }
            });
        },
        arquivoSelecionado(event) {
            this.templates = [];
            event.files.forEach((element) => {
                let template = {
                    id: this.classe.id,
                    classe: this.classe.nome,
                    title: element.name,
                    disponivelCliente: false,
                    imagem: false,
                    arquivo: element,
                };
                this.templates.push(template);
            });
            this.modal = true;
        },
        modalClose() {
            this.modal = false;
            this.$refs.fileUpload.files = null;
        },
        uploadArquivo(template) {
            let uploaded = 0;
            template.forEach((element) => {
                ServiceArquivo.uploadArquivo(element, (loaded, total) => this.onUploadProgress(loaded, total, element)).then((response) => {
                    if (response?.success) {
                        uploaded++;
                    }
                    if (uploaded == template.length) {
                        this.arquivosLoading = [];
                        this.respostaSalvar(response, false);
                        this.obterArquivos();
                    }
                });
                this.arquivosLoading.push(element);
            });
        },
        atualizarArquivo(arquivo) {
            this.templates = [];
            this.modal = true;
            this.isAtualizar = true;
            this.templates.push(arquivo);
        },
        atualizar(arquivo) {
            ServiceArquivo.atualizarArquivo(arquivo[0].uuid, arquivo[0]).then((response) => {
                if (response?.success) {
                    this.respostaSalvar(response, true);
                    this.obterArquivos();
                }
            });
            this.isAtualizar = false;
        },
        deletarArquivo(arquivo) {
            ServiceArquivo.excluirArquivo(arquivo.uuid).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Exclusão de Arquivo',
                        detail: 'Arquivo excluído com sucesso',
                        life: 3000,
                    });
                    this.obterArquivos();
                }
            });
        },
        confirmarExclusao(arquivo) {
            this.$confirm.require({
                message: `Você deseja excluir este arquivo?`,
                header: 'Confirmação de Exclusão',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.deletarArquivo(arquivo);
                },
            });
        },
        download(arquivo) {
            this.$store.dispatch('addRequest');
            ServiceArquivo.downloadFileUrl(arquivo.urlDownload).then((response) => {
                if (response?.success) {
                    this.$download(response.data, arquivo.title);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Templates',
                        detail: 'Erro ao realizar download',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
        onUploadProgress(loaded, total, element) {
            element.progress = Math.floor((loaded * 100) / total);
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Arquivo(s)',
                    detail: `Arquivo(s) ${edicao ? 'atualizado(s)' : 'inserido(s)'} com sucesso`,
                    life: 3000,
                });
            } else {
                this.erros = response.errors;
            }
        },
    },
};
</script>

<style>
.p-fileupload-buttonbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-bottom: 4px;
}
</style>