<template>
    <div>
        <Dialog v-model:visible="displayModal" :breakpoints="{ '640px': '60vw' }" :style="{ width: '600px' }" :modal="true">
            <div>
                <div class="card">
                    <Steps :model="items" :readonly="true" :exact="false" />
                    <div v-if="index == 0" class="flex-column justify-content-center flex-wrap card-container">
                        <h4 class="flex justify-content-center">Informe o nome do arquivo:</h4>
                        <DataTable :value="arquivos" responsiveLayout="scroll">
                            <Column class="flex-grow-1 flex align-items-center justify-content-center">
                                <template #body="slotProps">
                                    <InputText class="flex-grow-1 flex align-items-center justify-content-center" v-model="slotProps.data.title" />
                                </template>
                            </Column>
                        </DataTable>
                        <Message
                            severity="error"
                            :closable="false"
                            :sticky="true"
                            v-if="titleInvalid"
                            class="bg-red-200 flex justify-content-center flex-wrap card-container yellow-container"
                            >Insira o nome do arquivo!</Message
                        >
                    </div>
                    <div v-if="index == 1" class="flex-column justify-content-center flex-wrap card-container">
                        <h4 class="flex justify-content-center">Você deseja disponibilizar o arquivo para o cliente?</h4>
                        <DataTable :value="arquivos" responsiveLayout="scroll">
                            <Column field="title" header="Arquivo"></Column>
                            <Column>
                                <template #body="slotProps">
                                    <SelectButton
                                        class="flex justify-content-center"
                                        v-model="slotProps.data.disponivelCliente"
                                        :options="options"
                                        optionLabel="label"
                                        optionValue="value"
                                        aria-labelledby="single"
                                    />
                                </template>
                            </Column>
                        </DataTable>
                        <Message
                            severity="error"
                            :closable="false"
                            :sticky="true"
                            v-if="disponivelClienteInvalid"
                            class="bg-red-200 flex justify-content-center flex-wrap card-container yellow-container"
                            >Você precisa selecionar algum!</Message
                        >
                    </div>
                </div>
                <div class="flex justify-content-between flex-wrap card-container">
                    <Button :disabled="index < 1" label="Voltar" @click="prevPage"></Button>
                    <Button v-if="index < 1" label="Próximo" @click="nextPage"></Button>
                    <Button v-if="index == 1" label="Salvar" @click="salvar"></Button>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
export default {
    props: {
        modal: {
            type: Boolean,
        },
        objeto: {
            type: Array,
        },
        isAtualizar: {
            type: Boolean,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            arquivos: [],
            index: null,
            options: [
                {
                    label: 'Sim',
                    value: true,
                },
                {
                    label: 'Não',
                    value: false,
                },
            ],
            items: [
                {
                    label: null,
                    to: '',
                },
                {
                    label: null,
                    to: '',
                },
            ],
            displayModal: false,
            position: 'center',
            titleInvalid: null,
            disponivelClienteInvalid: null,
        };
    },
    mounted() {
        this.arquivos = this.objeto.map(arquivo => { return {...arquivo}});
        if (this.modal) {
            this.index = 0;
            this.items[this.index].to = this.$router.currentRoute.value.fullPath;
            this.displayModal = true;
        }
    },
    validations() {
        return {
            arquivos: {
                $each: helpers.forEach({
                    title: {
                        required,
                    },
                    disponivelCliente: {
                        required,
                    },
                }),
            },
        };
    },
    methods: {
        prevPage() {
            if (this.index > 0) {
                this.items[this.index].to = null;
                this.index--;
            }
        },
        nextPage() {
            this.titleInvalid = false;
            if (this.index < 1) {
                if (!this.titleArquivo() && this.index == 0) this.index++;
                this.items[this.index].to = this.$router.currentRoute.value.fullPath;
            }
        },
        titleArquivo() {
            for (let x = 0; x < this.arquivos.length; x++) {
                if (this.v$.arquivos.$each.$response.$data[x].title.$invalid && this.index == 0) {
                    this.titleInvalid = true;
                }
            }
            return this.titleInvalid;
        },
        disponivelClienteArquivo() {
            for (let x = 0; x < this.objeto.length; x++) {
                if (this.v$.arquivos.$each.$response.$data[x].disponivelCliente.$invalid) {
                    this.disponivelClienteInvalid = true;
                }
            }
            return this.disponivelClienteInvalid;
        },

        salvar() {
            this.disponivelClienteInvalid = false;
            this.disponivelClienteArquivo();
            if (this.isAtualizar) {
                this.$emit('atualizar', this.arquivos);
                this.displayModal = false;
            } else {
                if (!this.disponivelClienteArquivo() && !this.isAtualizar){
                    this.$emit('salvar', this.arquivos);
                    this.displayModal = false;
                }
            }
        },
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },
    },
    watch: {
        displayModal() {
            if (!this.displayModal) {
                this.$emit('closeModal');
            }
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep(.p-steps-number) {
    margin-bottom: 10px;
    min-height: 50px;
    width: 50px;
}
</style>